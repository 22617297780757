import type { Chain, HttpTransport, Transport } from 'viem';
import { fallback, http } from 'wagmi';

import { config } from '@endaoment-frontend/config';

export const createTenderlyHttpTransport = (chain: Chain, apiKey: string): HttpTransport | undefined => {
  if (!chain.rpcUrls.tenderly) return undefined;
  const baseHttpUrl = chain.rpcUrls.tenderly.http[0];
  const fullUrl = `${baseHttpUrl}/${apiKey}`;
  return http(fullUrl, {
    batch: true,
  });
};

export const createInfuraHttpTransport = (chain: Chain, apiKey: string): HttpTransport | undefined => {
  if (!chain.rpcUrls.infura) return undefined;
  const baseHttpUrl = chain.rpcUrls.infura.http[0];
  const fullUrl = `${baseHttpUrl}/${apiKey}`;
  return http(fullUrl, {
    batch: true,
  });
};

export const createAlchemyHttpTransport = (chain: Chain, apiKey: string): HttpTransport | undefined => {
  if (!chain.rpcUrls.alchemy) return undefined;
  const baseHttpUrl = chain.rpcUrls.alchemy.http[0];
  const fullUrl = `${baseHttpUrl}/${apiKey}`;
  return http(fullUrl, {
    batch: true,
  });
};

export const createTransportsForChains = (chains: [Chain, ...Array<Chain>]): Record<number, Transport> => {
  return chains.reduce((acc: Record<number, Transport>, chain) => {
    const transportsByPriority = [
      http(undefined, {
        batch: true,
      }),
    ];

    const tenderly = createTenderlyHttpTransport(chain, config.providers.tenderly);
    if (tenderly) transportsByPriority.unshift(tenderly);

    const infura = createInfuraHttpTransport(chain, config.providers.infura);
    if (infura) transportsByPriority.unshift(infura);

    const alchemy = createAlchemyHttpTransport(chain, config.providers.alchemy);
    if (alchemy) transportsByPriority.unshift(alchemy);

    return {
      ...acc,
      [chain.id]: fallback(transportsByPriority, {
        rank: true,
        retryCount: 3,
      }),
    };
  }, {});
};
